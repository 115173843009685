import React from 'react';

import styles from './Section.module.scss';

export default ({ title, children, light, marginDisabled, className }) => {
  return (
    <section
      className={`${light ? styles.sectionWhite : styles.section} ${className}`}
    >
      <div
        className={`${marginDisabled ? '' : styles.margin} ${styles.maxWidth} `}
      >
        {title ? <h2 className={styles.title}>{title}</h2> : ''}
        {children}
      </div>
    </section>
  );
};
