import React from 'react';

import styles from './PageTitle.module.scss';

const PageTitle = ({ title, light }) => (
  <div className={light ? styles.wrapperLight : styles.wrapperDark}>
    <h1 className={light ? styles.light : styles.dark}>{title}</h1>
  </div>
);

export default PageTitle;
